/* @override
	http://localhost:8280/css/formate.css */


/* @group general page content */

body {
  background-color: #ffffff;
  font-size: 10pt;
  color: #2B2B2A;
  font-family: "Verdana", sans-serif;
  font-weight: normal;
}

@font-face {
	font-family: 'OCR-A Regular';
	src: url('../OCR-A Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

.ocr, h1, h2, h3, h4 {
	font-family: "OCR-A Regular", monospace;
	font-style: normal;
	font-weight: 400;
}

h1, h1.title {
  font-size: 16pt;
}

h2, h2.title {
  font-size: 14pt;
}

h3 {
	font-size: 12pt;
}

h4 {
	font-size: 10pt;
}

p {
  text-align: left;
}

.content {
	margin-top: 6em;
	margin-bottom: 5em;
}

p.copyright {
  color: #4FBDC1;
}

a {
  color: #1B72CE;
  text-decoration: none;
}
a:visited {
  color: #135091;
}
a:hover, a.plain:hover {
  text-decoration: underline;
}

a.plain {
	color: #2B2B2A;
	text-decoration: none;
}

/* @end */


/* @group header */

.header {
  font-size: 14pt;
}

.subheader {
  font-size: 12pt;
}

.silver {
  color: #B3B3B4;
}

.header_block {
	margin-bottom: 2em;
}

/* @end */

/* @group footer */

ul.foot {
	font-size: 8pt;
	list-style: none;
	padding-left: 0;
}

footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 47pt;
	background-color: #f5f5f5;
	padding-top: 10pt;
}
/* @end */

/* @group Startseite */


.contact_item {
	margin-top: 0.5em;
	margin-left: 18pt;
}

.motto {
	font-style: italic;
	text-align: center;
	margin-top: 4em;
	text-transform: lowercase;
	font-family: serif;
	font-size: 12pt;
}

/* @end */

/* @group imprint page  */

table.entry_table {
	margin-bottom: 1em;
}

.imprint_value {
	padding-left: 1em;
}
.imprint_header {
	font-weight: bold;
}

/* @end */



img {
	border: 0px;
}

td.label {
	text-align: right;
	vertical-align: top;
}

#qrcode {
	position: absolute;
	top: 1em;
	right: 1em;
}

#portrait {
	position: absolute;
	top: 2em;
	right: 6em;
}
